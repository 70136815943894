import axios from "axios";
// axios.defaults.baseURL =
//     import.meta.env.VITE_SERVER_API;

const service = axios.create({
	baseURL: "/api", //默认请求路径
});

// 添加请求拦截器
axios.interceptors.request.use(
	function(config) {
		console.log(config,"0000000000")
		// if (config.baseURL ===
		// 	import.meta.env.VITE_SERVER_API && config.url != '/wecom/login/doLogin') {
		// 	config.headers.wecomToken = JSON.parse(window.sessionStorage.getItem("token"));
		// }
		return config;
	},
	function(error) {
		console.log(error,"1111111111")
		// 对请求错误做些什么
		return Promise.reject(error);
	}
);

// 添加响应拦截器
axios.interceptors.response.use(
	function(response) {
		// 2xx 范围内的状态码都会触发该函数。
		// 对响应数据做点什么
		return response;
	},
	function(error) {
		// 超出 2xx 范围的状态码都会触发该函数。
		// 对响应错误做点什么
		return Promise.reject(error);
	}
);

export default axios;